* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Base styles */
.top-container {
    height: 95px;
    width: 100%;
    background-color: #d5eef4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .top-container .newdev {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
    
  .top-container a {
    outline: none;
    box-shadow: none;
  }
  .top-container img {
    max-width: 100px;
  }
   
  .side-bar-logo{
    display: none;
  }
  .sidebar-icons a{
    display: none;
  }
  
 .navbar-nav .nav-item a {
  font-weight: 550;
    font-size: 0.8rem;
    color: #5a5a5a;
  }
  .custom-nav-link {
    font-weight: 550;
    font-size: 0.8rem;
    color: #5a5a5a;
  }
  .navbar-nav .nav-item a:hover {
    color: #000000;
  }
  .custom-nav-link:hover{
    color: #000000;
  }
  .navbar {
    background-color: #d5eef4;
  }
  .container-fluid {
    background-color: #d5eef4;
  }
  .dropdown-menu {
    background-color: #d5eef4;
    border: none;
    display: none;
    opacity: 1;
    visibility: visible;
  }
  
  .dropdown-menu.show {
    display: block !important; /* Show dropdown on click in mobile view */
    opacity: 1;
    visibility: visible;
  }
  
  .dropdown-menu a {
    color: #000000;
    /* padding: 10px 20px; */
    text-decoration: none;
    display: block;
  }
  
  /* Aaradhya Foundation word */
 .navbar-brand {
    font-size: 25px;
    color: rgb(70, 100, 161);
    outline: none;
    box-shadow: none;
  }
  
  .foundation-brand {
    font-weight: bold;
    position: relative;
  }
  
  .registration-number {
    display: block;
    font-size: 14px;
    color: #595959;
    text-align: left;
  }
/* CTA Button Styling */
.cta-btnn {
    background-color: #fa2929; /* Primary red color */
    color: #ffffff; /* White text color */
    margin-top: -10px;
    padding: 8px 14px;
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 50px; /* Fully rounded corners for a pill-shaped button */
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .cta-btnn:hover {
    background-color: #4664a1; /* Dark blue on hover */
    color: #ffffff;
    transform: scale(1.05); /* Slightly enlarges button on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
  }
  
  /* Icon styling if you include an icon in the button */
  .cta-btnn .icon {
    margin-right: 8px; /* Space between icon and text */
    font-size: 1.2em; /* Slightly larger icon */
  }
  
  .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
    background-color: #d5eef4;
    /* background-color: rgb(255, 255, 255); */
    /* color: #ff0000; */
  }

  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1050px) {
    .nav-item a{
      color: red;
    }
  }
  
  @media (max-width: 1024px) {
    .top-container {
      /* padding: 0 0 5px; */
      padding-left: 3px;
      padding-right: 0px;
    }

    .navbar-nav .nav-item a{
      font-size: 0.65rem;
    }
    .custom-nav-link {
      font-size: 0.65rem;
    }
    .foundation-brand {
      font-size: 21px;
    }
    .cta-btnn {
      margin-top: -2%;
      padding: 5px;
      font-size: 0.7em;
    }
  }

  @media (min-width: 992px) {
    .dropdown:hover .dropdown-menu {
      display: block;
    }
  }  

  @media (max-width: 991px) {
    .navbar-toggler {
      order: 1;
      margin-right: 10px;
    }
  
    .navbar-collapse {
      position: fixed;
      top: 0;
      left: -250px;
      width: 300px;
      height: 100%;
      background-color: #d5eef4 ;
      transition: right 0.3s ease;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
      z-index: 1050;
      color: rgb(28, 28, 28);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      transition: all 0.3 ease-in-out;
    }

    .top-container img {
      margin-left: 20px;
    }
  
    .side-bar-logo{
      display: block;
      padding-top: 10px;
    }

    .navbar-brand {
      font-size: 25px;
    }
    .navbar-nav {
      padding-top: 50px;
    }
  
    .navbar-nav .nav-link {
      color: #1e1e1e !important;
      text-align: center;
      width: 100%;
      font-size: 18px;
      padding: 9px 0;
    }
    .navbar-nav .nav-item a{
      font-size: 18px;
    }
    .cta-btnn {
      width: 120px;
      height: 50px;
      font-size: 18px;
    }
  
    .navbar-collapse.show {
      left: 0;
    }
  
    .sidebar-icons{
      margin-top: 20px;
      display: flex;
      gap: 10px;
    }
  
    .sidebar-icons a{
      color: rgb(51, 51, 51);
      font-size: large;
      display: block;
    }

    .overlay {
      display: none;
    }
  
    .overlay.active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1049;
    }
  
    /* Make dropdown menus part of document flow in mobile view */
    .dropdown-menu {
      display: none;
    }
  
    .dropdown-menu li a{
      white-space: wrap;
    }
  
    .nav-item.dropdown .dropdown-toggle {
      display: inline-block;
    }
  
    /* Ensure dropdown pushes elements down in mobile */
    .dropdown-menu.show {
      display: block;
    }
  }

  @media (max-width: 786px) {
    .navbar-nav .nav-link {
      font-size: 17px;
    }
    .navbar-nav .nav-item a{
      font-size: 17px;
    }
    .cta-btnn {
      width: 120px;
      height: 50px;
      font-size: 18px;
    }
  }
  
  @media (max-width: 430px) {
    .navbar-toggler {
      order: 1;
      /* margin-right: 5px; */
    }

    .top-container img {
      margin-left: 5px;
    }
  
    .main-logo {
      width: 90px;
    }
    .foundation-brand {
      font-size: 18px;
      text-align: left;
    }
    .navbar-nav .nav-link {
      font-size: 16px;
    }
    .navbar-nav .nav-item a{
      font-size: 16px;
    }
    .cta-btnn {
      width: 100px;
      height: 40px;
      font-size: 17px;
    }
  }
  
  /* Media query for 390px width */
  @media (max-width: 390px) {
    .top-container img {
      margin-left: 2px;
    }

    .top-container {
      padding: 5px;
    }
    .main-logo {
      width: 80px;
    }
    .foundation-brand {
      font-size: 16px;
      text-align: center;
    }
    .navbar-nav {
      padding-top: 20px;
    }
    .navbar-nav .nav-link {
      font-size: 14px;
    }
    .navbar-nav .nav-item a{
      font-size: 14px;
    }
    .cta-btnn {
      width: 95px;
      height: 40px;
      font-size: 15px;
    }
  }

  @media (max-width: 361px) {
    .navbar-toggler {
      order: 1;
      margin-right: 0px;
    }
    .top-container img {
      margin-left: 0px;
    }
    .top-container {
      padding: 5px;
    }
    .main-logo {
      width: 70px;
    }
    .foundation-brand {
      font-size: 15px;
      text-align: center;
    }
    .navbar-nav {
      padding-top: 20px;
    }
  }