.contact-page-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
    font-family: Arial, sans-serif;
  }
  
  .form-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .form-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 48%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group.full-width textarea {
    width: 100%;
    height: 150px;
    resize: none;
  }
  
  .submit-button {
    width: 100px;
    background-color: #295bb1;
    color: white;
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #002080;
  }
  
  .card-section {
    flex: 0.5;
  }
  
  .card {
    background-color: #efefef;
    border: none;
    overflow: hidden;
    text-align: left;
    height: 100%;
  }
  
  .card img {
    width: 80%;
    height: 30%;
    margin: auto;
  }
  
  .card-content {
    padding: 10px 40px;
    font-size: 14px;
    line-height: 1.4;
  }
  .card-content h3 {
    font-size: 17px;
  }
  .card-content p {
    line-height: 1.428;
    font-size: 14px;
  }

  .error-message {
    color: red;
    font-size: 0.5rem;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  
  @media (max-width: 768px) {
    .contact-page-container {
      flex-direction: column;
    }
  
    .form-section {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .contact-page-container {
      padding: 10px 25px;
    }
  
    .form-section h2 {
      font-size: 18px;
    }
  
    .form-group textarea {
      height: 100px;
    }
  
    .card img {
      width: 60%;
    }
  
    .card-content h3 {
      font-size: 15px;
    }
  
    .card-content p {
      font-size: 12px;
    }
  }
  
  @media (max-width: 430px) {
    .contact-page-container {
      padding: 8px 20px;
    }
  
    .form-section h2 {
      font-size: 16px;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 13px;
    }
  
    .submit-button {
      font-size: 14px;
      padding: 8px;
    }
  
    .card img {
      width: 70%;
    }
  
    .card-content h3 {
      font-size: 14px;
    }
  
    .card-content p {
      font-size: 12px;
    }
  }