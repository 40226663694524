/* CSRActivities.css */
.csr-activities {
    padding: 50px 20px;
    background-color: #f7f7f7;
  }
  
  .csr-top-image {
    background-size: cover;
    background-position: center;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }
  
  .csr-top-image h1 {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .corporate-section {
    margin: 40px 0;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .corporate-section h3 {
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
    color: #295bb1;
  }
  
  .corporate-section p {
    font-size: 1rem;
    line-height: 1.8;
    color: #555;
    text-align: justify;
    margin-bottom: 15px;
  }
  
  .corporate-section ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .corporate-section li {
    margin-bottom: 10px;
    font-size: 1rem;
    text-align: justify;
    /* line-height: 1.8; */
  }

  .csr-activities h2 {
    font-size: 2rem;
    text-align: center;
    font-weight: 650;
    color: #295bb1;
  }
  
  .activity-slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    padding: 20px 0;
  }
  
  .activity-track {
    display: flex;
    width: calc(200%);
    /* animation: scrollLeft 15s linear infinite; */
    /* display: flex; */
    /* gap: 20px; */
    animation: scrollLeft 30s linear infinite;
  }

  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .activity-slider:hover .activity-track {
    animation-play-state: paused;
  }

  .activity-card {
    /* display: inline-block; */
    min-width: 300px;
    max-width: 300px;
    margin-right: 20px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .activity-card:hover {
    transform: scale(1.05);
  }

  .activity-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .activity-info {
    padding: 12px;
    text-align: center;
  }
  
  .activity-info h6 {
    font-size: 1.14rem;
    color: #295bb1;
    font-weight: 550;
    /* margin: 0; */
    text-align: center;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .activity-slider {
      overflow: hidden;
    }
  
    .activity-card {
      min-width: 250px;
    }
  
    .csr-activities h2 {
      font-size: 1.8rem;
    }
  
    .activity-info h6 {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 540px) {
    .csr-top-image {
        height: 200px; /* Reduced height */
    }

    .csr-top-image h1 {
        font-size: 2rem; /* Reduced font size */
    }

    .csr-activities h2 {
        font-size: 1.6rem;
    }

    .corporate-section h3 {
        font-size: 1.2rem;
    }

    .corporate-section p,
    .corporate-section li {
        font-size: 0.9rem;
    }

    .activity-card {
        min-width: 220px;
        max-width: 220px;
    }

    .activity-image {
        height: 160px;
    }

    .activity-info h6 {
        font-size: 0.8rem;
    }
}

@media (max-width: 430px) {
    .csr-top-image {
        height: 180px;
    }

    .csr-top-image h1 {
        font-size: 1.5rem;
    }

    .csr-activities h2 {
        font-size: 1.3rem; 
    }

    .corporate-section h3 {
        font-size: 1rem;
    }

    .corporate-section p,
    .corporate-section li {
        font-size: 0.85rem;
    }

    .activity-card {
        min-width: 200px;
        max-width: 200px;
    }

    .activity-image {
        height: 140px;
    }

    .activity-info h6 {
        font-size: 0.72rem;
    }
}