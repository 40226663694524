/* src/App.css */
*{
  margin: 0%;
  font-family: 'BarlowRegular', Arial, sans-serif;
  line-height: 1.428571429;
}

/*///// Contact Section Styling /////*/
.contact-section {
  background-color: #e0f7fa; /* Light blue background */
  padding: 40px;
  text-align: center;
}

.contact-title {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1; /* Dark blue color for title */
}

.contact-subtitle {
  font-size: 1.25rem;
  color: #37474f; /* Gray color for subtitle */
  margin-bottom: 20px;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.contact-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.contact-card h4 {
  font-size: 1.5rem;
  color: #042c6f;
  margin-bottom: 10px;
}

.contact-card p {
  font-size: 1rem;
  color: #37474f;
}

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #0288d1;
}
/* Responsive styles */
@media (max-width: 768px) {
  .contact-title {
    font-size: 1.75rem; /* Slightly smaller font size */
  }

  .contact-subtitle {
    font-size: 1.15rem; /* Adjust subtitle size */
  }

  .contact-card {
    padding: 15px; /* Less padding for smaller screens */
  }

  .contact-card h4 {
    font-size: 1.35rem;
  }

  .contact-card p {
    font-size: 0.95rem;
  }

  .icon {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .contact-title {
    font-size: 1.5rem; /* Further reduce the title size */
  }

  .contact-subtitle {
    font-size: 1rem; /* Smaller subtitle for mobile */
  }

  .contact-grid {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }

  .contact-card {
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .contact-card h4 {
    font-size: 1.2rem;
  }

  .contact-card p {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1.6rem;
  }
}


/*//////// Donate Section Styling //////////*/
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.donate-container {
  width: 100%;
  margin: 0 auto;
}

.donation-banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.donate-message-section {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;
  background-color: #f8f8f8;
  gap: 20px;
  align-items: flex-start;
}

.text-content {
  flex: 1;
  max-width: 60%;
}

.text-content h2 {
  font-size: 2rem;
  color: #2786c1;
  /* margin-bottom: 15px; */
}

.text-content p {
  font-size: 1rem;
  text-align: justify;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

.donation-card-container {
  flex: 1;
  margin-top: 75px;
  max-width: 40%;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.donation-card h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #2786c1;
}

.amount-options {
  display: block;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 20px;
}

.radio-label {
  display: inline-block;
  padding: 7px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
  background-color: #0288d1;
  transition: background-color 0.3s, border-color 0.3s;
}

.radio-label.selected {
  background-color: #f31111; /* Highlight color */
  color: white;
  /* border-color: #4caf50; */
}

.radio-label input {
  display: none; /* Hide the default radio button */
}

.quote p{
  /* padding: 0px; */
  font-size: 1rem;
}

.amount-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.donate-btn {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #2786c1;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.donate-btn:hover {
  background-color: #185a84;
}

.donation-form-card h2 {
  margin-bottom: 25px;
  color: #185a84;
}

.donation-form-card form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.donation-form-card textarea {
  height: 45px;
}

.donation-card-container .error-message {
  font-size: 14px;
}

/* .donate-designation {
  text-align: left;
} */

.custom-dropdown {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  padding: 5px;
  margin-left: 5px;
  appearance: none; /* Remove default arrow styling */
  outline: none;
  width: auto;
  /* font-weight: bold; */
}

.custom-dropdown:focus {
  border-bottom: 1px solid #f31111;
}

.custom-dropdown option {
  color: #333;
}

.error-text {
  color: red;
  font-size: 0.5rem;
  margin: 5px 0 0;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .donation-banner-image{
    height: auto;
  }
  .donate-message-section {
    padding: 15px 20px;
    gap: 15px;
  }

  .text-content {
    max-width: 70%;
  }

  .donation-card-container {
    max-width: 50%;
    margin-top: 40px;
    padding: 15px;
  }

  .text-content h2 {
    font-size: 1.8rem;
  }

  .text-content p {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 912px) {
  .text-content h2 {
    font-size: 1.59rem;
  }
  .donation-card-container {
    margin-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  .donation-banner-image {
    height: auto;
  }

  .donate-message-section {
    flex-direction: column;
    align-items: center;
    padding:40px;
  }

  .text-content {
    max-width: 100%;
  }

  .text-content p{
    font-size: 18px;
  }
  
  .donation-card-container {
    max-width: 100%;
    margin-top: 0px;
  }

  .text-content h2 {
    font-size: 1.5rem;
  }

  .donation-card h2 {
    font-size: 1.3rem;
  }

  .radio-label {
    padding: 5px 8px;
    font-size: 0.9rem;
  }

  .donate-btn {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .amount-input {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .donation-banner-image {
    height: auto;
  }

  .donate-message-section {
    padding: 10px 20px;
  }

  .text-content h2 {
    font-size: 1.2rem;
  }

  .text-content p {
    font-size: 0.9rem;
  }

  .donation-card h2 {
    font-size: 1.2rem;
  }

  .radio-label {
    padding: 4px 6px;
    font-size: 0.8rem;
  }

  .amount-input {
    font-size: 0.85rem;
    padding: 8px;
  }

  .donation-form-card textarea {
    max-height: 40px;
  }

  .donate-btn {
    font-size: 0.85rem;
    padding: 7px 12px;
  }
}

@media screen and (max-width: 390px) {
  .text-content h2 {
    font-size: 1.1rem;
  }
}


/* Hero Section Styling */
.hero-section {
  position: relative;
  width: 100%;
  height: 87.8vh; /* Height for larger screens */
  /* margin-top: 2px; */
  /* margin-bottom: 2px; */
  /* border-radius: 5px; */
  overflow: hidden; /* Ensures that slides don't overflow the container */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.hero-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  /* background-repeat: no-repeat; */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
}

.hero-slide.active {
  opacity: 1;
}

.hero-content {
  position: absolute;
  width: 90%;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: #f7f7f8;
  text-align: center;
  z-index: 10;
}

.hero-section h1 {
  font-size: 1.5rem;
  line-height: 1;
}
.cta-btn {
  padding: 8px 15px;
  background-color: rgb(70, 100, 161);
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  margin-bottom: -10px;
  /* transition: background-color 0.3s;  */
}

.cta-btn:hover {
  background-color: #333;
}

/* Navigation Buttons */
.carousel-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-nav button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.carousel-nav button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-nav button:focus {
  outline: none;
}

/* Responsive Media Queries */
/* @media (max-width: 1300px) {
  .hero-section {
    height: 66.7vh;
  }
} */

@media (max-width: 1024px) and (max-height: 1366px){
  .hero-section {
    height: 30.7vh;
  }
}
@media (max-width: 1024px) and (max-height: 600px) {
  .hero-section {
    height: 71vh; /* Reduce height for tablets */
  }

  .hero-content {
    bottom: 5%; /* Adjust text position */
  }

  .hero-section h1 {
    font-size: 1.25rem; /* Reduce heading size for tablets */
  }

  .cta-btn {
    font-size: 0.9rem;
    padding: 8px 16px; /* Smaller buttons on tablet */
  }
}

@media (max-width: 912px) and (max-height: 1368px){
  .hero-section {
    height: 28.2vh;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 31.5vh; /* Reduce height for mobile screens */
  }

  .hero-content {
    bottom: 7%; /* Adjust text position */
  }

  .hero-section h1 {
    font-size: 0.94rem; /* Smaller heading size on mobile */
  }

  /* .hero-section p {
    font-size: 1.2rem;
  } */

  .cta-btn {
    font-size: 0.85rem;
    padding: 7px 14px; /* Smaller buttons on mobile */
  }

  .carousel-nav button {
    font-size: 1.5rem; /* Smaller navigation buttons */
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 19.5vh; /* Further reduce height for very small screens */
  }

  .hero-content {
    bottom: 5%; /* Adjust text position */
  }

  .hero-section h1 {
    font-size: 0.7rem; /* Smaller heading size for small screens */
  }

  .hero-section .cta-btn {
    padding: 5px 5px;
    font-size: 0.5rem;
  }

  .cta-btn {
    font-size: 0.8rem;
    padding: 6px 12px; /* Even smaller buttons on small screens */
  }

  .carousel-nav button {
    font-size: 1.2rem; /* Further reduce navigation button size */
  }
}
@media (max-width: 412px) {
  .hero-section {
    height: 19vh; /* Further reduce height for very small screens */
  }
}
@media (max-width: 410px) and (max-height: 810px) {
  .hero-section {
    height: 20.5vh; /* Further reduce height for very small screens */
  }
}
@media (max-width: 376px) {
  .hero-section {
    height: 24vh; /* Further reduce height for very small screens */
  }
}


/* About Us Section Styling */
.mainabout {
  background-color: #eceef1;
  width: 1140px;
  margin: 0 auto;
}
.about-us {
  padding: 1rem 5rem;
  background-color: #eceef1;
  /* display: flex; */
  text-align: justify;
  justify-content: center;
}

.about-us-content {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  text-align: justify;
  margin: 0 auto;
  gap: 2rem;
}

.text-content {
  flex: 1.5;
  padding-right: 2rem;
}

.image-content {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  margin-top: 0.5%;
  /* justify-content: center; */
  gap: 0.5rem;
}

.image-content img {
  width: 400px;
  height: auto;
  max-height: 210px;
  border-radius: 5px;
  object-fit: cover;
  transition: all 0.3s ease-in-out
}

.about-us h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 1rem;
}

.about-us p {
  font-size: 17.2px;
  color: #0a0a0a;
  margin-bottom: 1rem;
  line-height: 1.5;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .mainabout {
    width: auto;
  }
  .about-us {
    padding: 1rem 2rem 1rem 4rem;
  }
}
@media (max-width: 912px) {
  .about-us {
    padding: 1rem 5rem;
  }
  .about-us-content {
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }
  .image-content {
    flex-direction: row;
  }
  .text-content {
    max-width: 900px;
    padding-right: 0px;
    padding-bottom: 1rem;
  }
  /* .image-content img {
    width: 345px;
  } */
  .image-content img {
    width: 320px;
    max-height: 250px;
    border-radius: 5px;
    object-fit: cover;
    transition: all 0.3s ease-in-out
  }
}
@media (max-width: 768px) {
  .about-us {
    padding: 1rem 3rem;
  }

  .about-us-content {
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }

  .text-content {
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .image-content {
    flex-direction: row;
    margin-top: 1rem;
  }
  .image-content img {
    width: 330px;
  }

  .about-us h2 {
    font-size: 2rem;
  }

  .about-us p {
    font-size: 16px;
  }

  .image-content img {
    max-height: 250px; /* Further reduce image height for smaller screens */
  }
}

@media (max-width: 550px) {
  .image-content {
    flex-direction: column;
    margin-top: 1rem;
  }
  .image-content img {
    width: 400px;
    max-height: 200px;
  }
}

@media (max-width: 480px) {
  .about-us {
    padding: 1rem 2rem;
  }
  .about-us h2 {
    font-size: 1.5rem;
  }
  .image-content img {
    width: 330px;
    max-height: 150px;
  }
  .about-us p {
    font-size: 14px;
  }
}
@media (max-width: 410px) and (max-height: 810px) {
  .image-content img {
    max-height: 180px;
  }
}
@media (max-width: 390px) {
  .image-content img {
    width: 290px;
    max-height: 150px;
  }
}

/* 4. Legacy Section Style*/
/* Background Section */
.background-section {
  background-color: #f5f5f5;
  padding: 90px 0;
  position: relative;
}

/* Legacy Section Box */
.legacy-section {
  background-color: #f5f5f5;
  padding: 40px 20px; /* Adjusted padding for smaller screens */
  margin: -60px auto; /* Overlapping the background */
  border-radius: 10px; /* Rounded corners */
  width: 90%; /* Adjusted width to be more responsive */
  max-width: 1200px; /* Max width to ensure it doesn’t stretch too much */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for an elevated look */
}

.legacy-section h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 30px;
}

.legacy-stats {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: space-around;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.legacy-item {
  text-align: center;
  flex: 1;
  margin: 10px; /* Added margin for spacing */
}

.legacy-icon {
  width: 400px;
  height: auto;
  padding: 10px;
  margin-left: 100px;
  /* margin-right: 50PX; */
  margin-bottom: 10px;
}

.legacy-item p {
  font-size: 18px;
  color: #666;
  text-align: justify;
}

/* Responsive Media Queries */
@media (max-width: 1024px) {
  .legacy-section {
    padding: 40px 15px; /* Reduce padding for tablets */
  }

  .legacy-item p {
    font-size: 1.1rem; /* Slightly smaller text */
    text-align: justify;
  }
  .legacy-icon{
    width: 40%;
    margin-left: 50px;
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .legacy-section {
    padding: 30px 10px; /* Further reduce padding for mobile screens */
    margin: -30px auto; /* Adjust margin for better spacing */
  }

  .legacy-item p {
    font-size: 1rem; /* Reduce text size for mobile */
    text-align: justify;
  }

  .legacy-stats {
    flex-direction: column; /* Stack items vertically on small screens */
  }

  .legacy-icon{
    width: 40%;
  }

  .legacy-item {
    margin-bottom: 20px; /* Increase bottom margin for vertical spacing */
  }
}

@media (max-width: 480px) {
  .legacy-section {
    padding: 20px 5px; /* Minimal padding for very small screens */
    margin: -20px auto; /* Adjust margin for small screens */
  }

  .legacy-section h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .legacy-item p {
    font-size: 14px; /* Further reduce text size */
    text-align: justify;
  }

  .legacy-icon {
    width: 80%;
    margin-left: 40px;
    padding: 0 0 10px; 
  }
}


/* Vision & Mission section Styling */
.vision-mission-section {
  background-color: #f0eeee;
  padding: 40px 30px;
  text-align: center;
}

.vision-mission-container {
  display: grid;
  gap: 20px; /* Space between cards */
  justify-content: center; /* Center align the cards */
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

/* Card styling */
.vision-mission-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* margin-bottom: 20px; */
}

.vision-mission-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.vision-mission-card .card-content {
  padding: 1px;
}

/* Image styling */
.card-image {
  width: 18%;
  height: auto;
  margin-bottom: -10px;
}

.card-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Heading styling */
.card-content h2 {
  font-size: 1.7rem;
  color: #295bb1;
  margin-bottom: 5px;
}

.vision-mission-card .card-content p {
  font-size: 15px;
  color: #555;
  text-align: justify;
  line-height: 1.428;
}
 .card-content li {
  font-size: 16px;
  color: #555;
  text-align: justify;
  line-height: 1.428;
}

.card-content li {
  margin-bottom: 10px;
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .vision-mission-card {
    width: 100%; /* Full width on smaller screens */
    max-width: 300px;
  }

  .card-content h2 {
    font-size: 25px;
  }

  .card-content p, .card-content li {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .vision-mission-card {
    width: 100%;
    max-width: 400px;
  }

  .card-content h3 {
    font-size: 1rem;
  }

  .card-content p, .card-content li {
    font-size: 14px;
  }
}


/* 6. President Message Section Style */
.president-message-section {
  padding: 40px 20px;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* background-color: #f4f5f7; */
  background: linear-gradient(90deg, rgba(31, 71, 230, 0.064) 35%, rgba(0,212,255,1) 100%);

}

.president-message-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.president-image {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 400px;
  display: flex;
  flex-direction: column; 
  gap: 1rem; /* Space between images */
}

.president-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.president-message-content {
  flex: 2;
}

.president-message-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color: #f18705e7;
}

.president-message-content p {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.428;
  margin-top: 10px;
}

/* .president-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
} */


/* Responsive Styles */
@media (max-width: 768px) {
  .president-message-container {
    flex-direction: column;
    text-align: center;
  }

  .president-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .president-message-section h2 {
    font-size: 28px;
  }

  .president-message-content p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .president-message-section h2 {
    font-size: 24px;
  }

  .president-message-content p {
    font-size: 14px;
  }
}


/* . Principal Message Section Style */
.principal-message-section {
  padding: 40px 20px;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* background-color: #f4f5f7; */
  background: linear-gradient(90deg, rgba(31, 71, 230, 0.064) 35%, rgba(0,212,255,1) 100%);

}

.principal-message-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.principal-image {
  flex: 1;
  text-align: center; 
  margin-right: 20px;
  margin-bottom: 106px; 
}

.principal-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.principal-message-content {
  flex: 2;
  /* margin-top: -230px;
  margin-right: 40px;
  width: 100%; */
}

.principal-message-section h2 {
  margin-top: 10px;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color:  #f18705e7;
}
.principal-message-content11 h2 {
  margin-top: 20px;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color:  #f18705e7;
}

.principal-message-content p {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.428;
  margin-top: 10px;
}

.principal-message-content11 p {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.428;
  margin-top: 10px;
}
.principal-message-content1 p {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.428;
  margin-top: 10px;
}

.principal-images img{
  width: 50%;
  border-radius: 30px;
  padding: 15px;
}

/* .principal-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
} */


/* Responsive Styles */
@media (max-width: 768px) {
  .principal-message-container {
    flex-direction: column;
    text-align: center;
  }

  .principal-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .principal-message-content h2 {
    font-size: 28px;
  }

  .principal-message-content p, .principal-message-content1 p, .principal-message-content11 p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .principal-message-section h2 {
    font-size: 24px;
  }

  /* .principal-image{
    margin-top: 200px;
  } */
  .principal-message-content p, .principal-message-content1 p, .principal-message-content11 p {
    /* margin-top: 200px; */
    font-size: 14px;
  }
}


/* . Secretary Message Section Style */
.secretary-message-section {
  background: linear-gradient(90deg, rgba(31, 71, 230, 0.064) 35%, rgba(0,212,255,1) 100%);
  padding: 40px 20px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  /* background-color: #f4f5f7; */
}

.secretary-message-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.secretary-image {
  flex: 1;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 296px;
}

.secretary-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.secretary-message-content {
  flex: 2;
}

.secretary-message-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color:  #f18705e7;
}

.secretary-message-content p {
  font-size: 18px;
  text-align: justify;
  color: #666;
  line-height: 1.428;
  margin-top: 10px;
}

/* .secretary-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
} */

/* Responsive Styles */
@media (max-width: 768px) {
  .secretary-message-container {
    flex-direction: column;
    text-align: center;
  }

  .secretary-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .secretary-message-section h2 {
    font-size: 28px;
  }
  .secretary-message-content{
    text-align: justify;
  }
  .secretary-message-content p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .secretary-message-section h2 {
    font-size: 24px;
  }

  .secretary-message-content p {
    font-size: 14px;
  }
}


/* Aims & Objectives Section Styling*/
.aims-objectives-section {
  padding: 40px 20px;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* background-color: #f4f5f7; */
  background: linear-gradient(90deg, rgba(31, 71, 230, 0.064) 35%, rgba(0,212,255,1) 100%);
}

.aims-objectives-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.aims-objectives-image {
  flex: 1;
  /* text-align: center; */
  margin-right: 20px;
  margin-bottom: 260px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.aims-objectives-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.aims-objectives-content {
  flex: 2;
}

.aims-objectives-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color:  #f18705e7;
}

.aims-objectives-content p, .aims-objectives-content ol {
  font-size: 18px;
  text-align: justify;
  color: #666;
  line-height: 1.428;
  margin-top: 10px;
}

/* .secretary-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
} */

/* Responsive Styles */
@media (max-width: 768px) {
  .aims-objectives-container {
    flex-direction: column;
    text-align: justify;
  }

  .aims-objectives-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .aims-objectives-section h2 {
    font-size: 28px;
  }

  .aims-objectives-content p, .aims-objectives-content ol {
    font-size: 16px;
    text-align: justify;
  }
}

@media (max-width: 480px) {
  .aims-objectives-section h2 {
    font-size: 22px;
  }

  .aims-objectives-content p, .aims-objectives-content ol {
    font-size: 14px;
    text-align: justify;
  }
}


/* Target Group Section Styling*/
.target-group-section {
  padding: 40px 20px;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* background-color: #f4f5f7; */
  background: linear-gradient(90deg, rgba(31, 71, 230, 0.064) 35%, rgba(0,212,255,1) 100%);
  /* background-color: orange blue; */
}

.target-group-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.target-group-image{
  flex: 1;
  text-align: center; 
  margin-right: 20px;
  margin-bottom: 330px; 
}
.target-group-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.target-group-content {
  flex: 2;
}

.target-group-content1 {
  margin-left: 80px;
  margin-right: 80px;
}
.target-group-content2 {
  margin-left: 48px;
  margin-right: 80px;
}

.target-group-section h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color:  #f18705e7;
}

.target-group-content p {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.428;
  margin-top: 10px;
}
.target-group-content1 p {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.428;
  margin-top: 10px;
}
.target-group-content2 ol {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.428;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .target-group-container {
    flex-direction: column;
    text-align: center;
  }

  .target-group-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .target-group-section h2 {
    font-size: 28px;
  }

  .target-group-content p {
    font-size: 16px;
  }
  .target-group-content1 p {
    font-size: 16px;
    margin-left: -80px;
    margin-right: -80px;
  }
  .target-group-content2 ol {
    font-size: 16px;
    text-align: left;
    margin-left: -80px;
    margin-right: -80px;
  }
}

@media (max-width: 480px) {
  .target-group-section h2 {
    font-size: 19px;
  }
  .target-group-section h4{
    font-size: 15px;
    margin-left: -80px;
    /* text-align: left; */
  }

  .target-group-image{

    margin-bottom: 10px;
  }

  .target-group-content p {
    font-size: 14px;
  }
  .target-group-content1 p {
    font-size: 14px;
    margin-left: -80px;
    margin-right: -80px;
  }
  .target-group-content2 ol{
    font-size: 14px;
    text-align: left;
    margin-left: -80px;
    margin-right: -80px;
  }
}



/* CSRActivities.css */
/* .csr-activities {
  padding: 50px 20px;
  background-color: #f7f7f7;
}

.csr-top-image {
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.csr-top-image h1 {
  font-size: 3rem;
  font-weight: bold;
}

.corporate-section {
  margin: 40px 0;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.corporate-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.corporate-section p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 15px;
}

.corporate-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.corporate-section li {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.activity-slider {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
}

.activity-card {
  min-width: 300px;
  max-width: 300px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.activity-card:hover {
  transform: translateY(-10px);
}

.activity-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  animation: slideIn 1.5s ease;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.activity-info {
  padding: 15px;
}

.activity-info h3 {
  font-size: 1.5rem;
  color: #08090a;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .activity-slider {
    flex-wrap: nowrap;
  }
}

@media (max-width: 576px) {
  .activity-card {
    min-width: 250px;
  }

  .csr-activities h2 {
    font-size: 2rem;
  }

  .activity-info h3 {
    font-size: 1.2rem;
  }
} */



/* 7. Courses Section Styles */
.courses {
  background-image: url('./assets/images/jxmufapp.png');
  background-size: cover;
  background-position: center;
  padding: 2rem 1rem;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #0d0c0c;
  text-align: center;
}

.courses h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #fc1937;
}

.admission-button {
  background-color: #fc1937;
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
/* Hover effect for button */
.admission-button:hover {
  background-color: #ff4c4c;
  transform: scale(1.05);
}

.course-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.course-card {
  background-color: #f8f5f5;
  padding: 2rem;
  margin: 1rem;
  border-radius: 10px;
  max-width: 300px;
  box-shadow: 0 0 10px rgba(18, 25, 17, 0.1);
  display: flex;
  flex-direction: column; /* Ensure content is stacked vertically */
  justify-content: space-between; /* Space out content vertically */
  transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.1s ease; /* Smooth transitions for hover effect */
}

/* Hover effect */
.course-card:hover {
  transform: scale(1.05); /* Slight zoom-in */
  background-color: #e0e0e0; /* Change background color */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Deepen shadow */
}

.course-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.course-card ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  flex-grow: 1; /* Allow the list to take up remaining space */
}

.course-card li {
  margin-bottom: 10px; /* Adds space between list items, adjust as needed */
}


/* 8. Awards and Accomplishments Section */
.awardsaccomplishments-section {
  background-color: #eaeaea;
  padding: 30px 20px;
  text-align: center;
}

.awardsaccomplishments-title {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px;
}

.awardsaccomplishments-subtitle {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 30px;
}
.awardsaccomplishments-slide-track {
  display: flex;
  justify-content: center;
  gap: 20px;
}
/* Flex container for cards */
.awardsaccomplishments-cards {
  display: flex;
  overflow-x: auto; /* Scroll horizontally if needed */
  white-space: nowrap; 
  gap: 20px; /* Space between cards */
  margin-bottom: 20px;
  padding: 10px 0; /* Vertical padding to ensure space around cards */
}

/* Card styling */
.awardaccomplishments-card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the content stays centered and space is distributed */
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 300px; /* Set a fixed height for uniformity */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* margin-right: 20px; */
  margin-bottom: 10px;
}

/* Remove the last card margin */
.awardsaccomplishments-cards .awardaccomplishments-card:last-child {
  margin-right: 0;
}

/* Hover effect */
.awardaccomplishments-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.awardaccomplishments-image {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  display: block;
}

/* Heading styling */
.awardaccomplishments-card h3 {
  font-size: 0.8rem;
  color: #295bb1;
}

.awardaccomplishments-card h4 {
  font-size: 0.9rem;
  color: #2c3e50; /* Darker heading */
  margin-bottom: 10px;
}

.awardaccomplishments-card p {
  font-size: 0.875rem;
  color: #555;
  margin-bottom: auto; /* Pushes the paragraph down to the bottom */
}

/* Responsiveness for smaller screens */
@media (max-width: 912px) {
  .awardsaccomplishments-slide-track {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .awardsaccomplishments-title {
    font-size: 2rem;
  }

  .awardsaccomplishments-subtitle {
    font-size: 1rem;
  }

  .awardsaccomplishments-cards {
    flex-direction: column; /* Stack cards vertically */
    
    align-items: center; /* Center cards */
    gap: 15px; /* Adjust gap for smaller screens */
  }

  .awardaccomplishments-card {
    width: 90%; /* Full width on small screens */
    height: auto; /* Auto height */
  }
}

@media (max-width: 480px) {
  .awardsaccomplishments-title {
    font-size: 1.5rem;
  }

  .awardsaccomplishments-subtitle {
    font-size: 14px;
  }

  .awardsaccomplishments-cards {
    gap: 10px; /* Smaller gap for extra small screens */
  }

  .awardaccomplishments-card {
    width: 95%; /* Full width with slight margin */
    height: auto; /* Auto height */
    padding: 15px;
  }

  .awardaccomplishments-image {
    width: 100px;
  }

  .awardaccomplishments-card h4 {
    font-size: 1.1rem;
  }
}


/* 9. Announcements and News Updates Setion Style*/
/* Base Styles */
.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 30px;
}

.section {
  width: 45%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.section-title {
  text-align: center;
  margin: 0 0 20px 0;
  color: #295bb1;
  font-size: 1.3rem;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.item:last-child {
  border-bottom: none; /* Remove bottom border from the last item */
}

.item-title {
  font-size: 16px;
  color: #555;
}

.item-link {
  background-color: #295bb1;
  color: white;
  padding: 5px 15px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

.item-link:hover {
  background-color: #4198f6;
  color: white;
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }

  .section {
    width: 100%;
  }

  .section-title {
    font-size: 1.3rem;
  }

  .item-title {
    font-size: 16px;
  }

  .item-link {
    font-size: 0.8rem;
    padding: 4px 12px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.1rem;
  }

  .item-title {
    font-size: 14px;
  }

  .item-link {
    font-size: 0.7rem;
    padding: 3px 10px;
  }
}


/* Container for the whole gallery section */
.gallery-section {
  text-align: center;
  background-color: #fff;
  padding: 40px 0;
}

.gallery-title {
  font-size: 2rem;
  font-weight: bold;
  color: #242323; /* Adjust the color to match your theme */
  margin-bottom: 20px;
}

/* Grid layout for the gallery items */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 0 20px;
}

/* Each item in the gallery */
.gallery-item {
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-item:hover {
  transform: scale(1.05);
}

/* Style for the View Gallery button */
.gallery-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f97316; /* Adjust button color */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.gallery-button:hover {
  background-color: #ff6600; /* Adjust hover effect */
}


/*///////////////Gallery Details ///////////////////*/
.gallery-detail-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 80px;
  /* width: 1200px; */
  /* background-color: #fef2f2; */
}
.gallery-detail-section h3 {
  font-size: 2rem;
  color: #0288d1;
  text-align: start;
  font-weight: 550;
}
.gallery-detail-section p {
  font-size: 16px;
  text-align: justify;
  /* max-width: 1100px; */
}
.gallery-detail-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 20px;
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
}

.gallery-detail-images img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gallery-detail-images img:hover {
  transform: scale(1.05); /* Zoom in on hover */
}

.carousel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
}

.carousel-container {
  width: 90vw; /* Responsive width */
  max-width: 800px; /* Max width for larger screens */
  height: auto; /* Height adjusts based on aspect ratio */
  max-height: 80vh; /* Limit height to 80% of viewport */
  background: transparent;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure full image is visible */
  max-width: 100%;
  max-height: 100%; /* Keep image within container height */
}

.close-carousel {
  position: absolute;
  top: 1px;
  right: 10px;
  background: transparent;
  border: none;
  /* padding: 1px ; */
  font-size: 24px; /* Increased size for better visibility */
  color: white; /* Use white for better contrast on dark background */
  cursor: pointer;
  /* color: #000; */
  z-index: 1001;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .gallery-detail-section h3 {
    font-size: 1.5rem;
  }

  .gallery-detail-section p {
    font-size: 16px;
  }

  .gallery-detail-images {
    gap: 15px;
  }
}
/* Responsive for screens with max-width 540px */
@media (max-width: 540px) {
  .gallery-detail-section {
    padding: 20px 40px; /* Reduce horizontal padding */
  }
  
  .gallery-detail-section h3 {
    font-size: 1.4rem; /* Adjust font size for better readability */
  }

  .gallery-detail-section p {
    font-size: 14px; /* Slightly smaller font for paragraphs */
    text-align: justify;
  }

  .gallery-detail-images {
    gap: 15px; /* Decrease gap between images */
  }

  .gallery-detail-images img {
    border-radius: 6px; /* Reduce border-radius */
  }
  
  .carousel-container {
    width: 85vw;
    max-height: 75vh;
  }
}

/* Responsive for screens with max-width 430px */
@media (max-width: 430px) {
  .gallery-detail-section {
    padding: 15px 20px; /* Further reduce padding */
  }
  
  .gallery-detail-section h3 {
    font-size: 1.2rem; /* Smaller font size for very small screens */
  }

  .gallery-detail-section p {
    font-size: 13px; /* Adjust paragraph font size */
    line-height: 1.5; /* Improve readability */
  }

  .gallery-detail-images {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Smaller grid items */
    gap: 10px; /* Decrease gap for compact layout */
  }

  .gallery-detail-images img {
    border-radius: 5px; /* Further reduce border-radius */
  }
  
  .carousel-container {
    width: 80vw; /* Adjust width for smaller screens */
    max-height: 70vh; /* Limit height more */
  }

  .close-carousel {
    font-size: 20px; /* Slightly smaller close button */
  }
}

/* 11. Advantages Section Style */
.advantage-section {
  /* background-color: #f8f8f8; */
  padding: 40px 20px; /* Added padding to reduce on smaller screens */
  text-align: center;
}

/* Title and Subtitle Styling */
.advantage-titles {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px; /* Added margin for spacing */
}

/* Grid Layout for Advantages Section */
.advantage-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual Advantage Cards */
.advantage-card {
  /* width: 90%; */
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  /* margin-left: 80px; */
  /* margin-right: 80px; */
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* text-align: center; */
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
}

/* .advantage-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
} */

.advantage-card h3 {
  font-size: 1.3rem;
  color: #295bb1;
  text-align: justify;
  margin-bottom: 10px;
}

.advantage-card ul {
  list-style: none;
  padding: 0;
}

.advantage-card ul li {
  /* margin:0; */
  text-align: justify;
  font-size: 18px;
  color: #37474f;
}

/* Mobile and Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {
  .advantage-titles {
    font-size: 2rem;
  }

  .advantage-subtitle {
    font-size: 1.1rem;
  }

  .advantage-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust grid layout */
  }

  .advantage-card h3 {
    font-size: 1.25rem;
  }

  .advantage-card ul li {
    font-size: 16px;
  }
}

/* Extra Small Devices (max-width: 480px) */
@media (max-width: 480px) {
  .advantage-titles {
    font-size: 1.6rem;
  }

  .advantage-subtitle {
    font-size: 1rem;
  }

  .advantage-grid {
    grid-template-columns: 1fr; /* Single column on very small screens */
    gap: 15px; /* Reduced gap for smaller screens */
  }

  .advantage-card {
    padding: 15px;
  }

  .advantage-card h3 {
    font-size: 1.1rem;
  }

  .advantage-card ul li {
    font-size: 14px;
  }
}


/* .icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #0288d1;
} */


/* 12. Fee Structure Section Style */
.fee-structure-section {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

/* Title Styling */
.fee-structure-section h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 30px;
}

/* Table Styling */
.fee-table {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  border-collapse: collapse;
  background-color: #fff;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Table Head Styling */
.fee-table thead {
  background-color: #a5a2a3;
  color: #fff;
}

/* Table Cells */
.fee-table th, .fee-table td {
  padding: 10px;
  font-size: 18px;
  text-align: left;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

/* Center-align Sr. No. */
.fee-table th:first-child, .fee-table td:first-child {
  padding-right: 30px;
  text-align: center;
}

/* Right-align Grant Requested INR */
.fee-table th:last-child, .fee-table td:last-child {
  text-align: right;
}

.fee-table th {
  font-weight: bold;
}

.fee-table tr:last-child td {
  border-bottom: none;
}

/* Hover Effect */
.fee-table tbody tr:hover {
  background-color: #f0f0f0;
}

/* Mobile and Tablet Responsiveness */
@media (max-width: 768px) {
  .fee-structure-section h2 {
    font-size: 28px;
  }

  .fee-table th, .fee-table td {
    font-size: 16px;
    padding: 12px 15px;
  }

  .fee-table {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .fee-structure-section h2 {
    font-size: 24px;
  }

  .fee-table {
    width: 100%;
    font-size: 14px;
  }

  .fee-table th, .fee-table td {
    font-size: 14px;
    padding: 10px;
    word-wrap: break-word; /* Prevents long words from breaking layout */
  }

  .fee-table th:first-child,
  .fee-table td:first-child {
    text-align: center; /* Center-align text in small screens */
  }

  .fee-table th:last-child,
  .fee-table td:last-child {
    text-align: right; /* Right-align text in small screens */
  }
}

/* Extra Small Screens (max-width: 360px) */
@media (max-width: 360px) {
  .fee-structure-section h2 {
    font-size: 20px;
  }

  .fee-table th, .fee-table td {
    font-size: 12px;
    padding: 8px;
  }
}


/* 13. Centers Loction Section Style*/
/* .centers-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #fff;
}

.content {
  max-width: 45%;
}

.content h4 {
  color: #f03;
  font-size: 18px;
  margin-bottom: 10px;
}

.content h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.content h1 span {
  color: #ff4081;
}

.content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.content ul li {
  font-size: 18px;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.book-visit, .explore-centers {
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-visit {
  background-color: #fff;
  border: 1px solid #000;
}

.book-visit:hover {
  background-color: #f0f0f0;
}

.explore-centers {
  background-color: #ff4081;
  color: #fff;
}

.explore-centers:hover {
  background-color: #e81f66;
}

.image-container {
  position: relative;
  max-width: 50%;
}

.image-container img {
  width: 100%;
  border-radius: 10px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-button button {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 12px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.play-button button:hover {
  background-color: rgba(255, 255, 255, 1);
} */


/* . Testimonials Section Styles */
.testimonials {
  padding: 2rem;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
}

.testimonials h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 1rem;
}

.testimonials-list {
  display: flex;
  justify-content: center;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}

.testimonial {
  width: 280px;
  max-width: 300px;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  background-color: #f3f3ec69;
  box-shadow: 0 5px 10px rgba(35, 4, 4, 0.1);
  text-align: center;
}

.testimonial-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: -0.5rem;
}

.testimonial p {
  font-size: 18px;
  color: #090909;
  margin-bottom: 0.5rem;
}

.testimonial h4 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #484851;
}

/* Mobile and Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {
  .testimonials h2  {
    font-size: 1.8rem;
  }
}


/* 13. Footer Section Styles */
.footer {
  background-color: #f1f2f2 ;
  color: #101010;
  padding: 1rem 2rem;
  text-align: center;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Wrapper for BDH Logo and social icons */
.footer-BDHlogo-socials {
  flex: 1 1 20%; /* Adjust width for this column */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center content horizontally */
  margin: 0.5rem;
}

.footer-BDHlogo img {
  width: 140px; 
  height: 145px;
  /* margin-bottom: -1.2rem; */
}

.footer-socials {
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footer-socials .social-icon {
  /* color: #121111; */
  font-size: 1.5rem;
  margin: 0 10px;
  text-decoration: none;
  font-family: 'BarlowRegular', Arial, sans-serif;
}
.footer-socials .social-icon:hover {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.footer-address {
  flex-direction: column;
  flex: 2;
  /* flex: 2 1 33%; */
  margin: 0.5rem;
  text-align: left;
}
.footer-address a {
  text-decoration: none;
  color: inherit;
}

.footer-address p strong {
  font-weight: bold;
}

.footer-designed {
  width: 100%;
  background-color: #4e4e4e; /* Black background */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.footer-designed p {
  font-size: 14px;
  margin: 0;
  padding: 0 1rem;
}

.footer-designed p:first-child {
  flex: 1; /* Align to the left */
  text-align: left;
}

.footer-designed p:last-child {
  flex: 1; /* Align to the right */
  text-align: right;

}
.footer-designed a{
  text-decoration: none;
  color: white;
}
/* .footer-designed {
  text-align: center;
  margin-top: 1rem;
} */

/* .footer-logo {
  width: 50px;
  margin-left: 0.5rem;
  vertical-align: middle;
} */

.footer-visitor-counter {
  flex: 1 1 20%;
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center horizontally */
  justify-content: center;
  text-align: center; /* Center-align the content */
  margin: 0.5rem 0;
  margin-top: -5.2%;
}

.footer-visitor-counter p {
  margin: 0 0 4px 0;
  font-size: 16px; /* Adjust font size */
  font-family: 'BarlowRegular', Arial, sans-serif;
}

.footer-visitor-counter .visitor-count {
  font-size: clamp(1.6rem, 2vw, 3rem); /* Dynamically scale based on screen width */
  font-size: 1.6rem;
  font-weight: bold;
  color: #1b5b9f;
  background-color: white;
  width: 40%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  text-align: center; /* Ensure text is centered */
  padding: 0.2rem;
  /* border: 2px solid; */
  border-radius: 8px; /* Rounded corners for the count */
  box-shadow:  0 4px 6px rgba(0, 0, 0, 0.1);
  /* transition: transform 0.3s ease, font-size 0.3s ease; */
}
.footer-visitor-counter .visitor-count[data-length="1"] {
  transform: scale(1); /* Default size for single digits */
}

.footer-visitor-counter .visitor-count[data-length="2"] {
  transform: scale(1.1); /* Slightly larger for two digits */
}

.footer-visitor-counter .visitor-count[data-length="3"] {
  transform: scale(1.2); /* Larger for three digits */
}

.footer-visitor-counter .visitor-count[data-length="4"] {
  transform: scale(1.3); /* Larger for four digits */
}

.footer-visitor-counter .visitor-count[data-length="5"] {
  transform: scale(1.4); /* Larger for five digits */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column; /* Stack columns vertically */
    align-items: center;
  }

  .footer-socials,
  .footer-address {
    flex: 1 1 100%; /* Full width for each section */
    text-align: center;
  }

  .footer-BDHlogo img {
    width: 80px; /* Adjust logo size for smaller screens */
    height: 80px;
  }

  .footer-logo {
    width: 50px; /* Smaller logo for mobile */
  }
  .footer-designed {
    flex-direction: column; /* Stack items vertically */
    text-align: center;
  }

  .footer-designed p {
    padding: 0.5rem 0;
    text-align: center; /* Center-align text on smaller screens */
  }
  .footer-visitor-counter {
    margin-top: 0px;
  }
  .footer-visitor-counter .visitor-count {
    font-size: clamp(1.4rem, 2vw, 2.5rem); /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 14px;
    padding: 1rem; /* Reduce padding for very small screens */
  }

  .footer-columns {
    text-align: center;
  }

  .footer-BDHlogo img {
    width: 80px; /* Adjust logo size for smaller screens */
    height: 80px;
  }

  .footer-logo {
    width: 60px; /* Smaller logo for very small screens */
  }

  .social-icon {
    font-size: 1.2rem;
    margin: 0 5px;
  }

  .footer-address {
    text-align: center; /* Center address text for smaller screens */
  }
  .footer-visitor-counter .visitor-count {
    font-size: clamp(1.2rem, 3vw, 2rem); /* Adjust font size for very small screens */
  }
}
