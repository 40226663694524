.donation-form {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  
  .donation-form h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333333;
    text-align: center;
    border-bottom: 2px solid #dc3545;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  .donation-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .donation-form form div {
    display: flex;
    flex-direction: column;
  }
  
  .donation-form form div label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #555555;
  }
  
  .donation-form form div input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.9rem;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .donation-form form div input:focus {
    border-color: #dc3545;
  }
  
  .donation-form form button {
    background-color: #dc3545;
    color: white;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .donation-form form button:hover {
    background-color: #a82334;
  }
  
  /* For error handling or extra information */
  .donation-form form div small {
    color: #dc3545;
    font-size: 0.8rem;
    margin-top: 3px;
  }
  
  /* Responsiveness */
  @media screen and (max-width: 768px) {
    .donation-form {
      padding: 15px;
    }
  
    .donation-form h3 {
      font-size: 1.2rem;
    }
  
    .donation-form form button {
      font-size: 0.9rem;
      padding: 10px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .donation-form {
      padding: 10px;
    }
  
    .donation-form h3 {
      font-size: 1rem;
    }
  
    .donation-form form div label {
      font-size: 0.8rem;
    }
  
    .donation-form form div input {
      font-size: 0.8rem;
      padding: 8px;
    }
  
    .donation-form form button {
      font-size: 0.85rem;
      padding: 8px;
    }
  }
  