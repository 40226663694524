.mahilavikas-section {
    padding: 20px;
    /* background-color: #f9f9f9; */
  }
  
  .mahilavikas-container {
    display: flex;
    /* align-items: center; */
    max-width: 1200px;
    padding: 0 55px;
    margin: 0 auto;
    flex-wrap: wrap; 
  }
  
  .mahilavikas-image {
    flex: 1;
    display: flex;
    flex-direction: column; 
    gap: 0.8rem;
    align-items: center;
    /* justify-content: center; */
  }
  
  .mahilavikas-image img {
    width: 100%;
    height: 200px;
    margin-top: 2%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .mahilavikas-content {
    flex: 2;
    padding: 0 25px;
  }
  
  .mahilavikas-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    /* margin-left: -7%; */
    font-weight: 650;
    color: #295bb1;
  }
  
  .mahilavikas-content p {
    font-size: 17.5px;
    color: #666;
    text-align: justify;
    line-height: 1.5;
  }
  
  .download-button-container {
    /* text-align: center; */
    /* justify-content: left; */
    margin-top: 40px;
  }
  
  .download-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #295bb1;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    /* color: red; */
    background-color: #fb2424;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }

  .advantage-section {
    padding: 20px 40px;
  }
  
  .advantage-titles {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #295bb1;
  }
  
  .advantage-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .advantage-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 20px 0;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .advantage-card:hover {
    transform: translateY(-5px);
  }
  
  .advantage-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .advantage-card h3 {
    font-size: 1.5rem;
    color: #295bb1;
    margin-bottom: 10px;
  }
  
  .advantage-card ul {
    list-style-type: disc;
    padding-left: 15px;
    text-align: left;
  }
  
  .advantage-card ul li {
    font-size: 1rem;
    color: #666;
    margin-bottom: 5px;
  }
  
  /* Responsive Styles */
  @media (max-width: 912px) {
    .mahilavikas-container {
      flex-direction: column;
      text-align: center;
    }
    .mahilavikas-container {
      padding: 10px;
    }
    .mahilavikas-section h2 {
      margin-bottom: 0px;
    }
    .mahilavikas-image {
      flex-direction: row;
      padding: 0 40px;
      margin-bottom: 20px;
    }
    .download-button-container {
      margin-bottom: -20px;
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .mahilavikas-container {
      flex-direction: column;
      text-align: center;
    }
  
    .mahilavikas-content {
        padding: 0;
      }
    .mahilavikas-image {
      flex-direction: row;
      padding: 0 40px;
      margin-bottom: 20px;
    }

    .advantage-section {
      padding: 20px 30px;
    }
  
    .mahilavikas-section h2 {
      font-size: 28px;
    }
  
    .mahilavikas-content p {
      font-size: 16px;
    }
  }
  @media (max-width: 540px) {
    .mahilavikas-container {
      flex-direction: column;
      text-align: center;
    }
  
    .mahilavikas-content {
        padding: 0;
      }
    .mahilavikas-image {
      flex-direction: column;
      padding: 0 ;
      margin-bottom: 20px;
    }
  
    .mahilavikas-section h2 {
      font-size: 28px;
    }
  
    .mahilavikas-content p {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .mahilavikas-section h2, .advantage-titles {
      font-size: 23px;
    }
  
    .mahilavikas-content p {
      font-size: 14px;
    }
  }
  