.policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .policy-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
    color: #2786c1;
  }
  
  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p, ul {
    font-size: 1em;
    line-height: 1.5;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .policy-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.8em;
    }
  
    h2 {
      font-size: 1.3em;
    }
  
    p, ul {
      font-size: 0.9em;
    }
  }
  